<template lang="pug">
  .basic-settings-form
    .title
      span {{ $t("company_system.rate_plan_settings.rate_plan.details.basic_settings.title") }}
    .form
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.basic_settings.status')")
          span.required {{ $t('company_system.req') }}
        .form-row-field.flex-row
          AppToggle(
            :disabled="plan.unreleasable"
            v-model="plan.released"
          )
          .label
            span {{ $t("company_system.rate_plan_settings.rate_plan.details.basic_settings.release") }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.basic_settings.code')")
        .form-row-field
          BFormInput(
            type="text"
            name="code"
            v-model="plan.code"
            :placeholder="$t('company_system.rate_plan_settings.rate_plan.details.basic_settings.code')"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.basic_settings.name')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          BFormInput(
            type="text"
            :class="{ 'is-invalid': $v.plan.name.$error }"
            name="name"
            v-model="plan.name"
            :placeholder="$t('company_system.rate_plan_settings.rate_plan.details.basic_settings.name')"
          )
          span.text-length
            | {{ nameLabel }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.basic_settings.type')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          .type-radio.flex-row
            AppRadioButton(
              :value="plan.type === 'regular'"
              @change="changeType('regular')"
            )
            .label
              span {{ $t('company_system.rate_plan_settings.rate_plan.details.basic_settings.regular') }}
          .type-radio.flex-row
            AppRadioButton(
              :value="plan.type === 'campaign'"
              @change="changeType('campaign')"
            )
            .label
              span {{ $t('company_system.rate_plan_settings.rate_plan.details.basic_settings.campaign') }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.basic_settings.sales_period')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          AppDatepicker(
            :class="{ 'is-invalid': $v.plan.sale_start.$error || $v.plan.sale_end.$error }"
            :value="salesPeriod"
            range
            @change="changeSalesPeriod"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.basic_settings.sales_day')")
          span.required {{ $t('company_system.req') }}
        .form-row-field.flex-row
          .sale-day.flex-row(v-for="day in weekDays")
            AppCheckbox(
              :class="{ 'is-invalid': $v.plan.sale_days.$error }"
              v-model="plan.sale_days[day]"
            )
            .label
              span {{ $t(`company_system.rate_plan_settings.rate_plan.details.basic_settings.weekdays.${day}`) }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.basic_settings.publication_period')")
        .form-row-field
          AppDatepicker(
            :value="postPeriod"
            range
            @change="changePostPeriod"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.basic_settings.reservation_hours')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          .reservation-hour-radio.flex-row
            AppRadioButton(
              :value="plan.closing_out_type === shopHours"
              @change="changeClosingOutType(shopHours)"
            )
            .label
              span {{ $t('company_system.rate_plan_settings.rate_plan.details.basic_settings.apply_shop_reservation_hours') }}
          .reservation-hour-radio.input.flex-row
            AppRadioButton(
              :value="plan.closing_out_type === untilHour"
              @change="changeClosingOutType(untilHour)"
            )
            AppNumberInput(
              :class="{ 'is-invalid': isReservationOptionInvalid(untilHour, untilHour) }"
              :disabled="plan.closing_out_type !== untilHour"
              :value="getReservationOptionValue(untilHour, untilHour)"
              :min="1"
              @input="changeClosingOutOption(untilHour, $event)"
            )
            .label
              span {{ $t('company_system.rate_plan_settings.rate_plan.details.basic_settings.until_hour') }}
          .reservation-hour-radio.input.flex-row
            AppRadioButton(
              :value="plan.closing_out_type === daysBefore"
              @change="changeClosingOutType(daysBefore)"
            )
            AppNumberInput(
              :class="{ 'is-invalid': isReservationOptionInvalid(daysBefore, daysBefore) }"
              :disabled="plan.closing_out_type !== daysBefore"
              :value="getReservationOptionValue(daysBefore, daysBefore)"
              :min="1"
              @input="changeClosingOutOption(daysBefore, $event)"
            )
            .label
              span {{ $t('company_system.rate_plan_settings.rate_plan.details.basic_settings.day_before') }}
            AppNumberInput(
              :class="{ 'is-invalid': isReservationOptionInvalid(daysBefore, untilHour) }"
              :disabled="plan.closing_out_type !== daysBefore"
              :value="getReservationOptionValue(daysBefore, untilHour)"
              :min="1"
              @input="changeClosingOutOption(untilHour, $event)"
            )
            .label
              span {{ $t('company_system.rate_plan_settings.rate_plan.details.basic_settings.until_time') }}
          .reservation-hour-radio.input.flex-row
            AppRadioButton(
              :value="plan.closing_out_type === businessDayBefore"
              @change="changeClosingOutType('business_days_before')"
            )
            AppNumberInput(
              :class="{ 'is-invalid': isReservationOptionInvalid(businessDayBefore, daysBefore) }"
              :disabled="plan.closing_out_type !== businessDayBefore"
              :value="getReservationOptionValue(businessDayBefore, daysBefore)"
              :min="1"
              @input="changeClosingOutOption(daysBefore, $event)"
            )
            .label
              span {{ $t('company_system.rate_plan_settings.rate_plan.details.basic_settings.business_days_before') }}
            AppNumberInput(
              :class="{ 'is-invalid': isReservationOptionInvalid('business_days_before', 'until_hour') }"
              :disabled="plan.closing_out_type !== 'business_days_before'"
              :value="getReservationOptionValue('business_days_before', 'until_hour')"
              :min="1"
              @input="changeClosingOutOption('until_hour', $event)"
            )
            .label
              span {{ $t('company_system.rate_plan_settings.rate_plan.details.basic_settings.until_time') }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.basic_settings.sales_channel')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          AppDropdown(
            :class="{ 'is-invalid': $v.plan.ota_ids.$error }"
            multiple
            removable-labels
            allow-empty
            :items="translatedOtas"
            :value="selectedOtas"
            @select="selectOtas"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.basic_settings.sales_destination_office')")
        .form-row-field
          AppDropdown(
            multiple
            allow-empty
            removable-labels
            :items="shops"
            :value="selectedShops"
            @select="selectShops"
          )
    FormActionBar(
      :delete-enabled="isEdit"
      @delete="$emit('remove-plan')"
      @save="handleSave"
    )
</template>

<script>
  const NAME_MAX_LENGTH = 64

  // misc
  import { translateOtaList } from "@/helpers/common"
  import { CLOSING_OUT_TYPES } from "./constants"
  import { filter, map } from "lodash-es"
  import { atLeastOneMustBeSelected } from "./validators"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withValidations from "@/mixins/withValidations"
  import withScrollTop from "@/mixins/withScrollTop"

  // stores
  import otasModule from "@/config/store/company_otas"
  import shopsModule from "@/config/store/company_system/shops_settings/shops/basic_settings"
  import ratePlanModule from "@/config/store/company_system/rate_plan_settings/rate_plan/basic_settings"

  const otasMixin = withStoreModule(otasModule, {
    resetState: true,
    name: "companyOtas",
    readers: { otas: "items" },
    actions: { fetchOtas: "FETCH_ITEMS" }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    resetState: true,
    name: "companiesShops",
    readers: { shops: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const ratePlanMixin = withStoreModule(ratePlanModule, {
    name: "ratePlan",
    resetState: true,
    readers: { plan: "item" },
    actions: {
      fetchPlan: "FETCH_ITEM",
      createPlan: "CREATE_ITEM",
      updatePlan: "UPDATE_ITEM",
      deletePlan: "DELETE_ITEM"
    }
  })

  const validationsMixin = withValidations(({ required, requiredIf, minLength }) => ({
    plan: {
      name: {
        required,
        minLength: minLength(1)
      },
      ota_ids: { required },
      sale_days: { atLeastOneMustBeSelected },
      sale_start: { required },
      sale_end: { required },
      closing_out_type: { required },
      closing_out_options: {
        until_hour: {
          required: requiredIf(function() {
            return !this.isShopHoursReservationType
          }),
          minValue: function(value) {
            return value > 0 || this.isShopHoursReservationType
          }
        },
        days_before: {
          required: requiredIf(function() {
            return !this.isShopHoursReservationType && !this.isUntinHourReservationType
          }),
          minValue: function(value) {
            return value > 0 || this.isShopHoursReservationType || this.isUntinHourReservationType
          }
        }
      }
    }
  }))

  export default {
    components: {
      FormActionBar: () => import("../../../FormActionBar"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    mixins: [otasMixin, ratePlanMixin, shopsMixin, validationsMixin, withScrollTop],

    props: {
      planId: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        ...CLOSING_OUT_TYPES
      }
    },

    mounted() {
      this.fetchOtas()
      this.fetchShops({ pagination: { _disabled: true } })
      if (this.isEdit) {
        this.fetchPlan(this.planId)
      }
    },

    methods: {
      textLength(length, maxLength) {
        return `(${length}/${maxLength})`
      },

      changeType(type) {
        this.plan.type = type
      },

      changeClosingOutType(type) {
        this.plan.closing_out_type = type
      },

      selectShops(shops) {
        this.plan.companies_shop_ids = map(shops, "id")
      },

      selectOtas(otas) {
        this.plan.ota_ids = map(otas, "id")
      },

      isReservationOptionInvalid(type, option) {
        return this.plan.closing_out_type === type && this.$v.plan.closing_out_options[option].$error
      },

      getReservationOptionValue(type, option) {
        return this.plan.closing_out_type === type ? this.plan.closing_out_options[option] : undefined
      },

      changeClosingOutOption(option, value) {
        this.plan.closing_out_options[option] = value
      },

      changeSalesPeriod(period) {
        this.plan.sale_start = period[0]?.toString()
        this.plan.sale_end = period[1]?.toString()
      },

      changePostPeriod(period) {
        this.plan.post_start = period[0]?.toString()
        this.plan.post_end = period[1]?.toString()
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".is-invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
        })
      },

      async sendRequest() {
        const { id, ...plan } = this.plan
        if (this.isEdit) {
          await this.updatePlan({ id, plan })
        } else {
          await this.createPlan(plan)
        }
      }
    },

    computed: {
      translatedOtas() {
        return translateOtaList(this.otas)
      },

      isEdit() {
        return !!(this.plan.id || this.planId)
      },

      weekDays() {
        return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday", "holiday"]
      },

      nameLength() {
        return this.plan.name?.length || 0
      },

      nameLabel({ nameLength }) {
        return this.textLength(nameLength, NAME_MAX_LENGTH)
      },

      selectedOtas() {
        return filter(this.translatedOtas, ({ id }) => this.plan.ota_ids.includes(id))
      },

      selectedShops() {
        return filter(this.shops, ({ id }) => this.plan.companies_shop_ids.includes(id))
      },

      salesPeriod({ plan: { sale_start, sale_end } }) {
        return sale_start && sale_end ? [new Date(sale_start), new Date(sale_end)] : []
      },

      postPeriod({ plan: { post_start, post_end } }) {
        return post_start && post_end ? [new Date(post_start), new Date(post_end)] : []
      },

      isShopHoursReservationType() {
        return this.plan.closing_out_type === "shop_hours"
      },

      isUntinHourReservationType() {
        return this.plan.closing_out_type === "until_hour"
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/mixins/company-system.sass"
  @import "@/assets/styles/variables.sass"

  .basic-settings-form
    +styled-inputs

    input
      height: 38px

    .is-invalid
      ::v-deep
        input
          +default-invalid-input

        .app-checkbox-icon
          +default-invalid-input

        .dropdown-toggle
          +default-invalid-input

    padding-bottom: 20px

    .flex-row
      display: flex
      justify-content: left

    .title
      height: 40px
      border-left: 13px solid $default-purple-light
      padding: 10px
      font-weight: 700
      font-size: 16px

    .form
      padding: 5px

      &-row
        display: flex
        flex-direction: column
        margin-top: 20px

        &-label
          +form-label

          span.required
            margin-left: 10px
            font-size: 12px
            color: $default-red

        &-field
          margin-top: 5px
          font-weight: 400
          font-size: 16px

          .text-length
            font-size: 12px
            color: $default-gray-medium

          .label
            font-size: 13px
            margin-left: 10px

          .type-radio
            margin-top: 10px

          .mx-datepicker-range
            width: 100%

            ::v-deep
              input
                padding-left: 30px

              .mx-icon-clear,
              .mx-icon-calendar
                left: 8px

          .sale-day
            margin-right: 5px
            .app-checkbox
              padding-right: 0
            .label
              margin-left: 0
              margin-top: 5px

          .reservation-hour-radio
            margin-bottom: 10px

            &.input
              .label,
              .app-radio-button
                margin-top: 7px
                margin-right: 7px

              input
                width: 60px

          .app-select
            padding: 0
</style>
